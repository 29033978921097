import { CalendarToday } from "@mui/icons-material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ScienceIcon from "@mui/icons-material/Science";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Person4Icon from "@mui/icons-material/Person4";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";

export const DOCTORS = [
  {
    title: "Travis Hernandez",
    specialization: "Oncologist",
    availableDate: "2022-02-06",
  },
  {
    title: "Christine Becker",
    specialization: "Pediatrician",
    availableDate: "2022-07-01",
  },
  {
    title: "Marie Gonzalez",
    specialization: "Plastic surgery",
    availableDate: "2022-12-02",
  },
  {
    title: "Tiffany Howard",
    specialization: "Surgent",
    availableDate: "2022-01-12",
  },
  {
    title: "Christopher Thompson",
    specialization: "Pediatrician",
    availableDate: "2022-03-05",
  },
  {
    title: "Victoria Bailey",
    specialization: "Surgent",
    availableDate: "2022-01-27",
  },
  {
    title: "Kaitlyn Dawson",
    specialization: "Oncology",
    availableDate: "2022-04-18",
  },
  {
    title: "Dana Floyd",
    specialization: "Surgent",
    availableDate: "2022-03-28",
  },
  {
    title: "Latoya Mckinney",
    specialization: "Pediatrician",
    availableDate: "2022-09-20",
  },
  {
    title: "Jesus Williams",
    specialization: "Psychiatrist",
    availableDate: "2022-04-16",
  },
  {
    title: "Timothy Brooks",
    specialization: "Surgent",
    availableDate: "2022-12-10",
  },
  {
    title: "Karen Clark",
    specialization: "Surgent",
    availableDate: "2022-12-28",
  },
  {
    title: "Jonathan Lowe",
    specialization: "Nurologist",
    availableDate: "2022-09-06",
  },
  {
    title: "William Dunn",
    specialization: "Oncologist",
    availableDate: "2022-06-11",
  },
  {
    title: "Michelle Steele",
    specialization: "Nurologist",
    availableDate: "2022-02-01",
  },
  {
    title: "Savannah Hoffman",
    specialization: "Surgent",
    availableDate: "2022-07-07",
  },
  {
    title: "Jamie Stewart",
    specialization: "Surgent",
    availableDate: "2022-12-28",
  },
  {
    title: "Benjamin Dunn",
    specialization: "Nurologist",
    availableDate: "2022-09-15",
  },
  {
    title: "James French",
    specialization: "Pediatrician",
    availableDate: "2022-03-26",
  },
  {
    title: "Heather George",
    specialization: "Surgent",
    availableDate: "2022-04-20",
  },
  {
    title: "Crystal Davis",
    specialization: "Surgent",
    availableDate: "2022-04-09",
  },
  {
    title: "George Kline",
    specialization: "Surgent",
    availableDate: "2022-10-14",
  },
  {
    title: "Krista Martinez",
    specialization: "Ophthalmology",
    availableDate: "2022-08-28",
  },
  {
    title: "Andrew Thompson",
    specialization: "Ophthalmologist",
    availableDate: "2022-08-24",
  },
  {
    title: "Eric Russell",
    specialization: "Cardiologist",
    availableDate: "2022-12-24",
  },
  {
    title: "Nancy Webb",
    specialization: "Ophthalmology",
    availableDate: "2022-10-07",
  },
  {
    title: "Edward Howard",
    specialization: "Cardiologist",
    availableDate: "2022-04-13",
  },
  {
    title: "Carlos Nelson",
    specialization: "Plastic surgery",
    availableDate: "2022-12-14",
  },
  {
    title: "Courtney Cardenas",
    specialization: "Ophthalmology",
    availableDate: "2022-10-01",
  },
  {
    title: "Keith Torres",
    specialization: "Oncologist",
    availableDate: "2022-06-09",
  },
  {
    title: "Jonathan Huynh",
    specialization: "Ophthalmology",
    availableDate: "2022-06-05",
  },
  {
    title: "Willie Hill",
    specialization: "Plastic surgery",
    availableDate: "2022-06-20",
  },
  {
    title: "Heather Peterson",
    specialization: "Nurologist",
    availableDate: "2022-09-24",
  },
  {
    title: "Zachary Henderson",
    specialization: "Surgent",
    availableDate: "2022-07-01",
  },
  {
    title: "Charles Hodges",
    specialization: "Psychiatrist",
    availableDate: "2022-05-01",
  },
  {
    title: "Gregory Mann",
    specialization: "Plastic surgery",
    availableDate: "2022-05-18",
  },
  {
    title: "Jenna Rosales",
    specialization: "Surgent",
    availableDate: "2022-01-22",
  },
  {
    title: "Albert Price",
    specialization: "Nurologist",
    availableDate: "2022-02-10",
  },
  {
    title: "Tina Gonzalez",
    specialization: "Oncology",
    availableDate: "2022-07-15",
  },
  {
    title: "Kyle Boone",
    specialization: "Ophthalmology",
    availableDate: "2022-06-21",
  },
  {
    title: "Kevin Richmond",
    specialization: "Oncology",
    availableDate: "2022-02-26",
  },
  {
    title: "Connie Smith",
    specialization: "Plastic surgery",
    availableDate: "2022-01-04",
  },
  {
    title: "Ashley King",
    specialization: "Psychiatrist",
    availableDate: "2022-05-25",
  },
  {
    title: "Patricia Steele",
    specialization: "Ophthalmology",
    availableDate: "2022-06-05",
  },
  {
    title: "Scott Osborne",
    specialization: "Cardiologist",
    availableDate: "2022-09-19",
  },
  {
    title: "Shane Chandler",
    specialization: "Pediatrician",
    availableDate: "2022-07-15",
  },
  {
    title: "Michael Rios",
    specialization: "Nurologist",
    availableDate: "2022-12-05",
  },
  {
    title: "Michael Simpson",
    specialization: "Pediatrician",
    availableDate: "2022-07-09",
  },
  {
    title: "Kevin Larson",
    specialization: "Psychiatrist",
    availableDate: "2022-03-13",
  },
  {
    title: "Joe Smith",
    specialization: "Psychiatrist",
    availableDate: "2022-05-04",
  },
  {
    title: "Ryan Kline",
    specialization: "Plastic surgery",
    availableDate: "2022-04-09",
  },
  {
    title: "James Hoover",
    specialization: "Surgent",
    availableDate: "2022-04-14",
  },
  {
    title: "Donna Walsh",
    specialization: "Oncology",
    availableDate: "2022-09-18",
  },
  {
    title: "Dave Jones",
    specialization: "Nurologist",
    availableDate: "2022-05-12",
  },
  {
    title: "Linda Dudley",
    specialization: "Ophthalmology",
    availableDate: "2022-04-04",
  },
  {
    title: "Justin Vega",
    specialization: "Plastic surgery",
    availableDate: "2022-01-06",
  },
  {
    title: "Jennifer Anderson",
    specialization: "Surgent",
    availableDate: "2022-07-05",
  },
  {
    title: "Karen Hensley",
    specialization: "Pediatrician",
    availableDate: "2022-12-27",
  },
  {
    title: "Thomas Foster",
    specialization: "Pediatrician",
    availableDate: "2022-01-17",
  },
  {
    title: "Kevin Cooper",
    specialization: "Ophthalmology",
    availableDate: "2022-02-06",
  },
  {
    title: "Amy Miles",
    specialization: "Oncology",
    availableDate: "2022-03-12",
  },
  {
    title: "Kimberly Davis",
    specialization: "Oncologist",
    availableDate: "2022-01-06",
  },
  {
    title: "Donald Middleton",
    specialization: "Cardiologist",
    availableDate: "2022-10-20",
  },
  {
    title: "Kimberly Dean",
    specialization: "Cardiologist",
    availableDate: "2022-12-21",
  },
  {
    title: "Edwin Benjamin",
    specialization: "Surgent",
    availableDate: "2022-02-22",
  },
  {
    title: "Perry Park",
    specialization: "Nurologist",
    availableDate: "2022-01-21",
  },
  {
    title: "Roberto Ramos",
    specialization: "Plastic surgery",
    availableDate: "2022-09-07",
  },
  {
    title: "Samuel Carr",
    specialization: "Ophthalmology",
    availableDate: "2022-07-20",
  },
  {
    title: "Ashley Miller",
    specialization: "Plastic surgery",
    availableDate: "2022-10-21",
  },
  {
    title: "Chelsea Maldonado",
    specialization: "Oncologist",
    availableDate: "2022-06-27",
  },
  {
    title: "Jeff Peterson",
    specialization: "Ophthalmologist",
    availableDate: "2022-01-04",
  },
  {
    title: "Julia Wyatt",
    specialization: "Oncology",
    availableDate: "2022-02-05",
  },
  {
    title: "Gregory Meyers",
    specialization: "Psychiatrist",
    availableDate: "2022-10-12",
  },
  {
    title: "Sharon Bell",
    specialization: "Psychiatrist",
    availableDate: "2022-09-27",
  },
  {
    title: "Shane Melton",
    specialization: "Cardiologist",
    availableDate: "2022-09-16",
  },
  {
    title: "Daniel Case",
    specialization: "Surgent",
    availableDate: "2022-09-01",
  },
  {
    title: "Shannon Mcneil",
    specialization: "Ophthalmologist",
    availableDate: "2022-02-07",
  },
  {
    title: "Christina Walter",
    specialization: "Ophthalmology",
    availableDate: "2022-10-06",
  },
  {
    title: "Eric Garrett",
    specialization: "Plastic surgery",
    availableDate: "2022-03-01",
  },
  {
    title: "Anthony Davis",
    specialization: "Plastic surgery",
    availableDate: "2022-06-26",
  },
  {
    title: "Ronald Davis",
    specialization: "Oncologist",
    availableDate: "2022-12-24",
  },
  {
    title: "James Martinez",
    specialization: "Cardiologist",
    availableDate: "2022-03-13",
  },
  {
    title: "Richard Carlson",
    specialization: "Ophthalmologist",
    availableDate: "2022-12-21",
  },
  {
    title: "Susan Russell",
    specialization: "Ophthalmologist",
    availableDate: "2022-11-16",
  },
  {
    title: "Janet May",
    specialization: "Plastic surgery",
    availableDate: "2022-03-20",
  },
  {
    title: "Tony Huang",
    specialization: "Ophthalmology",
    availableDate: "2022-06-03",
  },
  {
    title: "Stacey Adams",
    specialization: "Nurologist",
    availableDate: "2022-12-13",
  },
  {
    title: "Cynthia Reyes",
    specialization: "Pediatrician",
    availableDate: "2022-09-11",
  },
  {
    title: "Margaret Hanna",
    specialization: "Oncology",
    availableDate: "2022-12-09",
  },
  {
    title: "Sarah Blackwell",
    specialization: "Ophthalmologist",
    availableDate: "2022-09-05",
  },
  {
    title: "Paul Thompson",
    specialization: "Ophthalmologist",
    availableDate: "2022-07-26",
  },
  {
    title: "Ann Smith",
    specialization: "Ophthalmology",
    availableDate: "2022-01-15",
  },
  {
    title: "Scott Roth",
    specialization: "Plastic surgery",
    availableDate: "2022-02-19",
  },
  {
    title: "Keith Fowler",
    specialization: "Cardiologist",
    availableDate: "2022-01-27",
  },
  {
    title: "Gregory Scott",
    specialization: "Ophthalmology",
    availableDate: "2022-03-04",
  },
  {
    title: "Jennifer Taylor",
    specialization: "Pediatrician",
    availableDate: "2022-06-27",
  },
  {
    title: "Teresa Wright",
    specialization: "Ophthalmology",
    availableDate: "2022-03-17",
  },
  {
    title: "Ricardo Francis",
    specialization: "Pediatrician",
    availableDate: "2022-08-27",
  },
  {
    title: "Beth Ingram",
    specialization: "Nurologist",
    availableDate: "2022-07-27",
  },
];

export const SPECIALITIES = [
  { title: "Surgent" },
  { title: "Psychiatrist" },
  { title: "Oncology" },
  { title: "Pediatrician" },
  { title: "Ophthalmology" },
  { title: "Plastic surgery" },
  { title: "Ophthalmologist" },
  { title: "Cardiologist" },
  { title: "Oncologist" },
];

export const FAQ = [
  {
    question: "Can I use any smartphone? ",
    answer:
      "You can use any smartphone with an internet connection, mobile or Wi-Fi internet connection to access service.",
  },
  {
    question: "Do I need to register for the service? ",
    answer:
      "No. to make an appointment but you need to register before the consultation so the doctor will need to know your details such as name, age, and gender.  ",
  },
  {
    question: "How do I make a payment? ",
    answer:
      "You will need to pay via Visa or MasterCard for a virtual consultation. ",
  },
  {
    question: "Do I need to install any applications? ",
    answer:
      "No. You do not need any application to download and install. Video call will be loaded in your internet browser application. Please allow microphone and camera access to the browser to enable video calls for the doctor consultation. ",
  },
  {
    question: "The video is too dark. What should I do? ",
    answer:
      "Please move to a room with sufficient light and a light source is best behind your smartphone directed towards you. ",
  },
  {
    question: "How do I join computer/device audio? ",
    answer:
      "On most devices, you can join computer/device audio by clicking Join Audio, Join with Computer Audio, or Audio to access the audio settings. Please allow the browser to access to camera and the microphone. If you have any further issues with connecting with voice and video, please refer to the phone settings and user manual of your phone or computer. ",
  },
  {
    question: "Can I record my consultation? ",
    answer:
      "No. For confidentiality reasons, we do not allow anyone to record the consultation but we do not control applications on your devices and any application may record without our knowledge.  ",
  },
  {
    question: "There is an echo in my meeting. ",
    answer:
      "Echo can be caused by many things, such as a participant connected to the meeting audio on multiple devices or two participants joining in from the same location. ",
  },
];

export const TERMS_AND_CONDITIONS = [
  {
    title:
      "General Terms and Conditions applicable for all services offered through MEDICA ",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `All customers using MEDICA shall be adjudged to have read and agreed to the terms and conditions stated herein.`,
          `All terms and conditions provided herein are applicable at the time of scheduling appointments via the Website, Mobile App or Partners. `,
          `TF has the sole authority to extend/discontinue all offers and promotions without any prior notice.`,
          `All Customers hereby agree to promotional materials/content to be displayed upon registration with MEDICA.`,
          `MEDICA has the sole authority to deactivate Customer accounts without prior notice in case of fraud or misconduct.`,
          `MEDICA shall not, under any circumstances, be responsible for, inter alia, the quality, accuracy, completeness, value and integrity of the advice provided by the Doctor.`,
          `MEDICA shall not be liable or responsible in any manner whatsoever for:`,
          [
            `All healthcare services channelled through MEDICA, including but not limited to diagnosis, prescription and other medical advice provided by hospitals, Doctors and/or any other healthcare professionals;`,
            `Any refunds for appointment cancellations by the hospital, Doctor or patient and for no show by patient, in the event the Customer has not subscribed to NSR Service. Any changes to the scheduled appointments are not within the control of TF and Customers should liaise directly with the respective hospital, in relation to such matters.`,
            `MEDICA service charge is non-refundable under any of above circumstance unless customer has opted for No Show Refund service.`,
          ],
          `Customers shall be responsible for the accuracy of Personal Information provided to MEDICA, for the purposes of scheduling the appointment. `,
          `MEDICA reserves the right to revise these Terms and Conditions at any time, at its discretion.`,
          `Nothing herein expressed or implied is intended to or shall be construed to confer upon or give any person other than the Customer any rights or remedies against MEDICA.`,
        ],
      },
    ],
  },
  {
    title: "Registered Customers",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `All customers who complete the MEDICA Sign Up Process shall be considered as registered customers of MEDICA.`,
          `Upon registration the user is solely responsible for the User ID and password and for all actions/activities/orders/inquiries made on MEDICA while logged in with his/her User ID.`,
          `A User must keep the User ID and password secure at all times.`,
          `We recognize whether a user is accessing the site and using the services by the User ID. Therefore, the user is responsible for any unauthorized access with his/her User ID and liable for any claims arising out of or in relation to such use.`,
          `If you are registering as a business entity, you represent that you have the authority to bind the entity to this User Agreement.`,
          `You agree to notify MEDICA immediately of any unauthorized use of your account or any other breach of security. MEDICA will be not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. You may be held liable for any losses incurred by MEDICA, or another party due to someone else using your account or password.`,
        ],
      },
    ],
  },
  {
    title: "Guest Customers",
    content:
      "All Customers who have not completed the MEDICA Sign Up Process will be considered as guest customers.",
    pointCotent: [],
  },
  {
    title: "Doctor Channelling",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `All Customers hereby agree to provide all required information when scheduling an appointment on MEDICA.`,
          `A Customer's appointment shall be confirmed upon completion and acceptance of the following payments.`,
          [
            `Doctor's charge`,
            `Hospital charge`,
            `MEDICA service charge &`,
            `all applicable government taxes and levies.`,
          ],
          `For all reserved appointments, Customers shall make the remaining payment 30 minutes prior to the scheduled appointment, via any eZ cash merchants island wide.`,
          `Reserved appointments which have not been paid in full as stated above, will be cancelled 30 minutes prior to the scheduled appointment.`,
          `Customers are required provide the booking reference number to the hospital when arriving for the scheduled appointment.`,
          `Appointment time provided to Customers by MEDICA is an approximate time and may change due to circumstances beyond the control of MEDICA.`,
          `The Customer should provide an email address when scheduling appointments in order to receive digital receipts. Alternatively, the Customer can visit the “My Appointments” feature on the Website, Mobile App or request digital receipts via the e – mail address service@doc.lk`,
        ],
      },
    ],
  },
  {
    title: "No Show Refund Policy",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `Customers shall be not be eligible for any refunds unless the medica service provided agrees directly with the customer for a refund. MEDICA shall not take any responsibility for any refund.`,
        ],
      },
    ],
  },
  
  {
    title: "Eligibility to use",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `Usage of MEDICA is restricted to persons who have the capacity to contract under Sri Lankan law.`,
          `Persons who do not have capacity to contract under the Sri Lankan Law are prohibited from using MEDICA.`,
          `We retain our right to refuse to provide access/services to any such person not having the capacity to contract under Sri Lankan Law and the right to terminate the account of any such person.`,
          `If you are registering as a business entity, you represent that you have the authority to bind the entity to this User Agreement.`,
        ],
      },
    ],
  },
  {
    title: "Restrictions on Usage",
    content: `By using MEDICA you acknowledge and agree that you are prohibited from using MEDICA for any of the following purposes`,
    pointCotent: [
      {
        title: ``,
        points: [
          `To disseminate any unlawful, harassing, libelous, defamatory, abusive, threatening, harmful, vulgar, obscene, pornographic, pedophilic, racially and ethnically objectionable or otherwise objectionable material`,
          `To transmit material that encourages conduct that constitutes a criminal offence, results in civil liability or otherwise breaches any relevant laws, regulations or code of practice`,
          `To host, display, upload, modify, publish, transmit, update or share any information on MEDICA that belong to MEDICA or any third party and to which you do not have any right`,
          `To harm minors in any way`,
          `To deceive or mislead the addressee about the origin of any message or to communicate any information which is grossly offensive or menacing in nature`,
          `To impersonate another person`,
          `To use software viruses/corrupt data or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource including MEDICA`,
          `To threaten the unity, integrity, defense, security or sovereignty of Sri Lanka, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation`,
          `To violate or attempt to violate the integrity or security of MEDICA or its content`,
          `To transmit any information (including job posts, messages and hyperlinks) on or through MEDICA that is disruptive or competitive to the provision of services by us`,
          `To intentionally submit on MEDICA any incomplete, false or inaccurate information`,
          `To use MEDICA to make any unsolicited communications to other users of MEDICA`,
          `To use any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search MEDICA`,
          `To copy or duplicate in any manner any of the content on MEDICA; and`,
          `To engage in framing or hot linking or deep linking any content on MEDICA`,
          `To use the site and the services by children under the age of 13`,
          `Unless otherwise specified in writing, to use services for your personal and non- commercial use.`,
          `Copy, reverse engineer, reverse assemble, otherwise attempt to discover the source code, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, software, products or services obtained through the Site or the services`,
          `Delete any author attributions, legal notices or proprietary designations or labels`,
          `Violate any applicable local, national or international law, rule or regulation or use the Site and/or the services for any purpose that is prohibited by these terms of use`,
          `Manipulate or otherwise display the Site and/or the services by using framing or similar navigational technology`,
          `Register, subscribe or unsubscribe any party for any MEDICA service if you are not expressly authorized by such party to do so`,
          `Use the Site or the services in any manner that could damage, disable, overburden or impair MEDICA’s servers or networks, or interfere with any other user's use and enjoyment of the Site and/or the services`,
          `Gain or attempt to gain unauthorized access to any of the Site, services, accounts, computer systems or networks connected to MEDICA through hacking, password mining or any other means`,
          `Obtain or attempt to obtain any materials or information through any means not intentionally made available through the Site or the services or harvest or otherwise collect information about other users without their consent`,
          `Use the site in any manner that could damage, disparage, or otherwise negatively impact MEDICA. In addition, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, COPYING OR REPRODUCING ANY SERVICES, PROGRAMS, PRODUCTS, INFORMATION OR MATERIALS PROVIDED BY MEDICA TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED.`,
        ],
      },
    ],
  },

  {
    title: "Restrictions on Services",
    content: `We have the sole discretion to determine and restrict the services made available to a User. The Users will be notified of such restrictions. We further retain the right to prohibit the provision of any services to any User at our discretion.`,
    pointCotent: [
      {
        title: ``,
        points: [],
      },
    ],
  },
  {
    title: "Content, Data and Information",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `By using MEDICA you acknowledge and agree that the contents of MEDICA, are for your general information only and are subject to change without notice. The medical or surgical information/solutions/consultations are given by a panel of registered and experienced medical doctors in Sri Lanka, with good intentions to help the public. The medical or surgical information/solutions/consultations provided via MEDICA only provide professional guidance and are not in any way intended to be a substitute for more elaborate professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or a Specialist regarding any further queries you may have regarding a medical or surgical condition.`,
          `By using MEDICA you further acknowledge and agree to not ignore professional medical advice or delay in seeking medical or surgical treatment because of any guidelines given by MEDICA or article you have read on our site. If you think you may have a medical emergency, you should take necessary steps to obtain necessary medical attention. Dependence on the medical or surgical information/solutions/consultations of our site is solely at your own risk and we take no responsibility for any adverse consequences resulting from such dependence.`,
          `The medical or surgical information/solutions/consultations provided online, email, chat, SMS, phone call, video calls or any other mean via MEDICA shall not be considered as definitive diagnosis of any sickness and shall not in any way used to prescribe definitive medicines or treatments for sickness. This service will be strictly limited to medical or surgical information/solutions/consultations provided for general information, guidance and prescribe medicine if relevant to its Users by professionally qualified and experienced medical doctors registered with Medical council of Sri Lanka. Moreover medical or surgical information/solutions/consultations provided online, email, chat, SMS, phone call, video calls or any other mean via MEDICA do not guarantee that the advice are from the specialist in that particular field.`,
          `No person reading this site, or contents within it, or using the responses by this website via online, email, chat, phone call, video calls or any other mean should act or refrain from acting on the basis of any information contained in this website or on the basis of any medical or surgical information/solutions/consultations provided without seeking appropriate medical or other professional advice on the particular facts and circumstances at issue.`,
          `Although we have taken all care and precaution to try and provide accurate data and information, we do not represent that MEDICA or any content, service or feature of the website will be error free, or that it will meet any particular criteria of accuracy, completeness or reliability of information, performance and/or quality. We do not give any warranty, express or implied, in relation to the operation of MEDICA website.`,
        ],
      },
    ],
  },
  {
    title: "Charges or Fees",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `Users can obtain a number of services such as Doctor Chanelling, Audio/Video Consultation service, medicine delivery , lab service through a “pay per use” facility.`,
          `Registration: The users who avail of the services of MEDICA will be able to register on the Website & App free of any charges. Those who are registered on the Website or App will be able to keep their medical records online and view/ edit when necessary for free.`,
          `However, after registration at the Website/App, other than the benefits and services given at the time of initial registration, all other services including Consultations, shall be provided for a fee charged by MEDICA. If you wish to obtain the services of MEDICA after registration (other than services specified in Clause 1 above) you shall be required to pay the fee prescribed by MEDICA.`,
          `Changes to any of the Charges or fees prescribed for the services of MEDICA or any new or additional fees made introduced or made applicable for the services of MEDICA shall be determined at the sole discretion of MEDICA and may be subject to change from time to time at the sole discretion of the Management Team of MEDICA and the Users shall be given adequate notice of such charges or fees or changes to such charges of fees.`,
          `MEDICA Daily Care package is only for Dialog & Hutch customers. If there is any due from the customer, the due amount will be subsequently charged up-to 30 days.`,
          `The MEDICA health & wellness package fees are non-refundable and your package will be automatically deactivated on the package expiry date. Daily Care & Monthly Care customers are eligible to deactivate the package anytime, but the charges will not be refunded.`,
        ],
      },
    ],
  },
  {
    title: "Intellectual Property Rights and Linking with Other Websites",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `All materials constituting the content of MEDICA is owned by MEDICA and/or third party and/or licensed to MEDICA. Such material includes, but is not limited to, the design, layout, look, appearance, information, text, trademarks, service marks, pictures, photographs, graphics, interfaces and arrangement of the content. Copying, reproduction, uploading, posting and/or using such material in any other form are strictly prohibited without written permission from MEDICA`,
          `All trademarks reproduced in MEDICA, which are not the property of, or licensed to MEDICA, are acknowledged on MEDICA.`,
          `Sri Lankan Intellectual Property Act No. 36 of 2003 applies to the content of MEDICA in relation to the intellectual property rights owned by MEDICA and all material and information displayed, transmitted or carried on MEDICA are protected under and in terms of the provisions of Intellectual Property Act No. 36 of 2003. Any intellectual properties owned by third parties that appear in MEDICA are protected by relevant laws applicable to such intellectual properties.`,
          `Use of any material or content of MEDICA without authorization may give rise to a claim for damages and/or criminal charges.`,
          `Any links to other websites referred to in MEDICA are provided only for your convenience and do not in any way represent that MEDICA endorse those websites. MEDICA takes no responsibility for the content and operation of any of those sites.`,
          `Creating a link from another website to MEDICA is prohibited without written permission from MEDICA.`,
        ],
      },
    ],
  },
  {
    title: "Limitation of Liability",
    content: "",
    pointCotent: [
      {
        title: ``,
        points: [
          `Neither MEDICA nor its, directors, officers, employees, contractors, agents or sponsors are responsible or liable to you or anyone else for any loss or injury or damages under any contract, negligence, strict liability or other theory arising out of or relating in any way to`,
          [
            `The use of or inability to use the site and/or the services`,
            `Any content contained on the site and/or the services;`,
            `Statements or conduct posted or made publicly available on the site and/or the services;`,
            `Any service purchased or obtained through the site and/or services;`,
            `Any action taken in response to or as a result of any information available on the site and/or services`,
            `Any damage caused by loss of access to, deletion of, failure to store, failure to back up, or alteration of any content on the site and/or services; or`,
            `Any other matter relating to the site and/or the services. In no event shall the total liability of MEDICA to you for any and all damages, losses, and causes of action exceed the amount paid by you, if any, for using the site and/or services.`,
          ],
        ],
      },
    ],
  },
];

export const WHAT_WE_OFFER = [
  {
    icon: (
      <EventAvailableIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Online Appointment ",
    content: `Book your doctor's appointment online for video consultation (Virtual)
              from anywhere or at the dispensary (Physical).`,
  },
  {
    icon: (
      <SafetyDividerIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Consultation​",
    content: `Consult a qualified SLMC registered doctor anytime from anywhere virtually.`,
  },
  {
    icon: (
      <ScienceIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Laboratory Services​",
    content: `Full laboratory services, sample collection from home, and results emailed to you and/or sent to directly.`,
  },
  {
    icon: (
      <LocalShippingIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Medicine Delivery​​",
    content: `Get your medication delivered right to your doorstep according to your health guidelines.​`,
  },
  {
    icon: (
      <HomeWorkIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Home Admissions​​",
    content: `Qualified, SLMC registered, well experienced Doctor consultation and Nursing, Physiotherapists, and attendants at home. We manage your complete health care needs at home.​`,
  },
  {
    icon: (
      <AirportShuttleIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: "Ambulance services​​",
    content: `24/7 ambulance services for any medical emergency to a private or government hospital.​​`,
  },
];

export const ONGOING_APPOINTMENTS = [
  {
    id: 1,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 2,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 3,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 4,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
];

import { Box, Typography } from "@mui/material";
import React from "react";

const TitleAndContent = ({ title, content, sx, titleSx, contentSx }) => {
  return (
    <Box sx={{ ...sx }}>
      <Typography
        sx={{
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#727272",
          fontSize: "0.8rem",
          fontWeight: "bold",
          ...contentSx,
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default TitleAndContent;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import DoctorCard from "../../components/DoctorCard/DoctorCard";
import SearchBar from "../../components/SearchBar/SearchBar";

const SearchDoctor = () => {
  const FOUND_DOCTORS = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <Box>
      <Box display={"flex"} justifyContent="center" mt={5} px={1}>
        <SearchBar />
      </Box>
      <Box display={"flex"} flexDirection="column" alignItems="center" mt={2}>
        <Box sx={{ width: { xs: "95%", lg: "90%" } }}>
          <Typography
            sx={{ pl: 1, my: 1, fontSize: "1.5rem", fontWeight: 550 }}
          >
            Here are the doctors we found for you...
          </Typography>
          <Grid container>
            {FOUND_DOCTORS.map((doctor, index) => {
              return (
                <Grid item xs={12} sm={6} lg={3} key={index}>
                  <DoctorCard />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ width: { xs: "95%", lg: "90%" } }}>
          <Typography
            sx={{ pl: 1, my: 2, fontSize: "1.5rem", fontWeight: 550 }}
          >
            By Speciality
          </Typography>
          <Grid container>
            {FOUND_DOCTORS.map((doctor, index) => {
              return (
                <Grid item xs={12} sm={6} lg={3} key={index}>
                  <DoctorCard />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchDoctor;

export const PRODUCTION = true;

export const APPOINTMENT_STATUS = {
  PENDING: "pending",
  ONGOING: "Pending",
  COMPLETED: "Successful Consultation",
  CANCELLED_AS_NO_SHOW: "Cancellation as “No Show�?",
  CANCELLED_WITH_RESCHEDULED: "Unsuccessful consultation with Rescheduling​",
  CANCELLED_WITH_REFUND: "Unsuccessful consultation with Refund",
};

export const PAYMENT_METHODS = {
  PAY_NOW: 2,
  CASH_PAYMENT: 5,
  PROMO: 6,
  PAY_LATER: 7, //No implementation yet
};


export const SESSION_CATEGORIES = {
  COUNSELLING:"Counselling",
  GENERAL_CLINIC:"General Health & Wellness Clinic",
  DIABETIC_FOOT_WOUND_CARE:"Diabetic Foot & Wound Care Center",
  ONLINE_CONSULTATION:"Online Video Consultation",
  GENERAL_SESSION:"General"
}

export const SESSION_CATEGORIES_REVERSED = {
  "Counselling":'COUNSELLING',
  "General Health & Wellness Clinic":'GENERAL_CLINIC',
  "Diabetic Foot & Wound Care Center":'DIABETIC_FOOT_WOUND_CARE',
  "Online Video Consultation":'ONLINE_CONSULTATION',
  "General":'GENERAL_SESSION'
}